import React from 'react';
import SEO from '../components/layout/seo';
import { useAuthUserCheckOrError } from '../libs/handleHttpError';
import MemberLayout from '../components/layout/member-layout';
import CustomerPointsView from '../components/customer/customer-points-view';
import { useStateValue } from '../store/state';
import pageAliases from '../data/pageAliases';
import {has} from 'lodash'
import navigate from '../libs/navigate';

const AccountPointsPage = () => {
  useAuthUserCheckOrError();
  const [state] = useStateValue();
  if (has(state, 'customer.CardNumber') && !state.customer.CardNumber && typeof window !== 'undefined') {
    navigate(pageAliases.accountCards);
  }
  return (
    <MemberLayout>
      <SEO title="Account Points" />
      <CustomerPointsView />
    </MemberLayout>
  );
};

export default AccountPointsPage;
